<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate">{{ $t("shops.back") }}</vs-button>

      <transition name="fade" mode="out-in">
        <div class="mb-base">
          <data-table
            v-if="!showForm"
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.catalog.0')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <catalog-form v-model="formData" 
            @submit="onCatalogFormSubmit" 
            @productSorting="onProductSort"
            @participantSorting="onParticipantSort"/>
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import Datepicker from "vuejs-datepicker";
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import CatalogForm from "@/modules/Shops/Pages/forms/CatalogForm.vue";
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Catalogs",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    Datepicker,
    DataTable,
    CatalogForm,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/catalogs", {
      catalogsList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      catalogById: "getById",
    }),

    tableData() {
      return this.catalogsList.map(this.catalogToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/catalogs", {
      loadData: "load",
      removeData: "remove",
      updateCatalog: "update",
      createCatalog: "create",
      sortCatalogProducts: "sortCatalogProducts",
      sortCatalogParticipants: "sortCatalogParticipants",
    }),

    catalogToTableData(catalog) {
      return {
        id: catalog.id,
        active: catalog.active,
        name: catalog.name,
        description: catalog.description,
        from_date: catalog.from_date,
        to_date: catalog.to_date,
        created_at: catalog.created_at,
        updated_at: catalog.updated_at,
      };
    },

    async onCatalogFormSubmit(catalog) {
      this.$vs.loading();
      try {
        if (catalog.id === 0) {
          await this.createCatalog(catalog);
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.catalog.0')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updateCatalog(catalog);
          this.notifySuccess(
            i18n.t("shops.update"),
            `${i18n.t('shops.catalog.0')} ${catalog.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_catalog"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onProductSort(payload) {
      const catalog = payload.catalog
      const productSortedArray = payload.productsSorted
      this.$vs.loading();
       try {
          await this.sortCatalogProducts({ id: catalog.id, productsOrder: productSortedArray});
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.catalog.0')} ${this.formData.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_catalog"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onParticipantSort(payload) {
      const catalog = payload.catalog
      const participantsSortedArray = payload.participantsSorted
      this.$vs.loading();
       try {
          await this.sortCatalogParticipants({ id: catalog.id, participantsOrder: participantsSortedArray});
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.catalog.0')} ${this.formData.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_catalog"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onUpdate(catalog) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign({},this.formData,
        { ...this.catalogById(catalog.id),
          translations: { ...translationsDefault, ...this.catalogById(catalog.id).translations }
        }
      );

      const fullCatalog = this.catalogsList.find((item) => item.id == catalog.id);
      this.formData.catalog_id = catalog.catalog_id;
      this.formData.products = fullCatalog.products;
      this.formData.participants = fullCatalog.participants;

      await this.$store.dispatch("shops/catalogs/assignTranslations",this.formData);
      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        active: false,
        translations: this.translationsDefault(['name', 'description']),
        name: "",
        description: "",
      }
    }
  },

  created() {
    this.$store.commit("shops/catalogs/RESET_RESOURCE");
    this.load({page: 1, page_size: this.perPage })
  },
};
</script>

<style>
.danger-border div {
  border-color: red;
}
</style>
