var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-form" },
    [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c("div", { staticClass: "vx-col w-1/2" }, [
          _c(
            "label",
            { staticClass: "vs-input--label" },
            [
              _vm._v(" " + _vm._s(_vm.$t("shops.active")) + " "),
              _c("vs-switch", {
                staticClass: "mt-3",
                attrs: { type: "boolean" },
                model: {
                  value: _vm.catalog.active,
                  callback: function($$v) {
                    _vm.$set(_vm.catalog, "active", $$v)
                  },
                  expression: "catalog.active"
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        _vm._l(_vm.languages(), function(language, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
              ]),
              _vm.langRequired(language)
                ? _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      name: "name",
                      danger: _vm.errorName && _vm.errors.has("name"),
                      "danger-text": _vm.$t("shops.errors.name_is_required")
                    },
                    model: {
                      value: _vm.catalog.name,
                      callback: function($$v) {
                        _vm.$set(_vm.catalog, "name", $$v)
                      },
                      expression: "catalog.name"
                    }
                  })
                : _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "name" + language },
                    model: {
                      value: _vm.catalog.translations[language].name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.catalog.translations[language],
                          "name",
                          $$v
                        )
                      },
                      expression: "catalog.translations[language].name"
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        _vm._l(_vm.languages(), function(language, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/3" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("shops.description_" + language)) + " "
                )
              ]),
              _vm.langRequired(language)
                ? _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "description" },
                    model: {
                      value: _vm.catalog.description,
                      callback: function($$v) {
                        _vm.$set(_vm.catalog, "description", $$v)
                      },
                      expression: "catalog.description"
                    }
                  })
                : _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { name: "description" },
                    model: {
                      value: _vm.catalog.translations[language].description,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.catalog.translations[language],
                          "description",
                          $$v
                        )
                      },
                      expression: "catalog.translations[language].description"
                    }
                  })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(" " + _vm._s(_vm.$t("shops.catalog.position")) + " ")
            ]),
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { name: "position" },
              model: {
                value: _vm.catalog.position,
                callback: function($$v) {
                  _vm.$set(_vm.catalog, "position", $$v)
                },
                expression: "catalog.position"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mt-6 mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.from_date")))
            ]),
            _c("datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: {
                "danger-border":
                  _vm.errorFromDate && _vm.errors.has("from_date")
              },
              attrs: {
                inline: true,
                mondayFirst: true,
                placeholder: "01/01/1970",
                danger: _vm.errorFromDate,
                "danger-text": _vm.$t("shops.errors.from_date_is_required"),
                name: "from_date"
              },
              model: {
                value: _vm.catalog.from_date,
                callback: function($$v) {
                  _vm.$set(_vm.catalog, "from_date", $$v)
                },
                expression: "catalog.from_date"
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
              },
              [
                _vm.errorFromDate && _vm.errors.has("from_date")
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("shops.errors.from_date_is_required"))
                      )
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.to_date")))
            ]),
            _c("datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              class: {
                "danger-border": _vm.errorToDate && _vm.errors.has("to_date")
              },
              attrs: {
                inline: true,
                mondayFirst: true,
                placeholder: "01/01/1970",
                danger: _vm.errorToDate,
                "danger-text": _vm.$t("shops.errors.to_date_is_required"),
                name: "to_date"
              },
              model: {
                value: _vm.catalog.to_date,
                callback: function($$v) {
                  _vm.$set(_vm.catalog, "to_date", $$v)
                },
                expression: "catalog.to_date"
              }
            }),
            _c(
              "div",
              {
                staticClass:
                  "con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
              },
              [
                _vm.errorToDate && _vm.errors.has("to_date")
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$t("shops.errors.to_date_is_required")))
                    ])
                  : _vm._e()
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "vs-button",
        {
          staticClass: "mt-base",
          attrs: { type: "filled" },
          on: {
            click: function($event) {
              return _vm.validateAndSubmit()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("shops.save")))]
      ),
      _vm.productsInCatalog.length > 0
        ? _c(
            "div",
            [
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("shops.products.title")))])
                ])
              ]),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("Datatable", {
                      attrs: {
                        config: _vm.productDatatableConfig,
                        elements: _vm.productsInCatalog,
                        stripe: true,
                        draggable: true,
                        sst: false
                      },
                      on: { sort: _vm.handleSortProduct }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.participantsInCatalog.length > 0
        ? _c(
            "div",
            [
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c("div", { staticClass: "vx-col" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("shops.participants.title")))])
                ])
              ]),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("Datatable", {
                      attrs: {
                        config: _vm.participantsDatatableConfig,
                        elements: _vm.participantsInCatalog,
                        stripe: true,
                        draggable: true,
                        sst: false
                      },
                      on: { sort: _vm.handleSortParticipants }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }