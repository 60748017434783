<template>
    <div class="catalog-form">
        <div class="vx-row mb-6">
            <div class="vx-col w-1/2">
                <label class="vs-input--label">
                    {{ $t("shops.active") }}
                    <vs-switch class="mt-3" type="boolean" v-model="catalog.active" />
                </label>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
                <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
                <vs-input v-if="langRequired(language)"
                    v-model="catalog.name"
                    v-validate="'required'"
                    class="w-full"
                    name="name"
                    :danger="errorName && errors.has('name')"
                    :danger-text="$t('shops.errors.name_is_required')" />
                <vs-input v-else v-model="catalog.translations[language].name" class="w-full" :name="`name${language}`" />
            </div>
        </div>

        <div class="vx-row mb-6">
            <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/3">
                <label class="vs-input--label"> {{$t(`shops.description_${language}`)}} </label>
                <vs-input v-if="langRequired(language)" class="w-full" name="description" v-model="catalog.description" />
                <vs-input v-else class="w-full" name="description" v-model="catalog.translations[language].description" />
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col">
                <label class="vs-input--label"> {{$t(`shops.catalog.position`)}} </label>
                <vs-input class="w-full" name="position" v-model="catalog.position" />
            </div>
        </div>

        <div class="vx-row mt-6 mb-6">
            <div class="vx-col">
                <label class="vs-input--label">{{ $t("shops.from_date") }}</label>
                <datepicker
                    v-bind:class="{'danger-border': errorFromDate && errors.has('from_date')}"
                    :inline="true"
                    :mondayFirst="true"
                    placeholder="01/01/1970"
                    v-validate="'required'"
                    :danger="errorFromDate"
                    :danger-text="$t('shops.errors.from_date_is_required')"
                    v-model="catalog.from_date"
                    name="from_date" />
                <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
                    <span v-if="errorFromDate && errors.has('from_date')">{{$t("shops.errors.from_date_is_required")}}</span>
                </div>
            </div>
            <div class="vx-col">
                <label class="vs-input--label">{{ $t("shops.to_date") }}</label>
                <datepicker
                    v-bind:class="{'danger-border': errorToDate && errors.has('to_date')}"
                    :inline="true"
                    :mondayFirst="true"
                    placeholder="01/01/1970"
                    v-validate="'required'"
                    :danger="errorToDate"
                    :danger-text="$t('shops.errors.to_date_is_required')"
                    v-model="catalog.to_date"
                    name="to_date" />
                <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
                    <span v-if="errorToDate && errors.has('to_date')">{{$t("shops.errors.to_date_is_required")}}</span>
                </div>
            </div>
        </div>
        <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
        <div v-if="productsInCatalog.length > 0" >
            <vs-divider />
            <div class="vx-row mb-6">
                <div class="vx-col">
                    <h3>{{ $t("shops.products.title") }}</h3>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col">
                    <Datatable 
                        :config="productDatatableConfig"
                        :elements="productsInCatalog"
                        :stripe="true"
                        :draggable="true"
                        :sst="false"
                        @sort="handleSortProduct"
                    />
                </div>
            </div>
        </div>
        <div v-if="participantsInCatalog.length > 0" >
            <vs-divider/>
            <div class="vx-row mb-6">
                <div class="vx-col">
                    <h3>{{ $t("shops.participants.title") }}</h3>
                </div>
            </div>
            <div class="vx-row mb-6" >
                <div class="vx-col">
                    <Datatable 
                        :config="participantsDatatableConfig"
                        :elements="participantsInCatalog"
                        :stripe="true"
                        :draggable="true"
                        :sst="false"
                        @sort="handleSortParticipants"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "@/i18n";
import Datepicker from "vuejs-datepicker";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'

export default {
    name: "CatalogForm",
    props: {
        value: { type: Object, default: null }
    },
    mixins: [ notifications, shopLanguages ],
    components: {
        Datepicker,
        Datatable
    },
    data() {
        return {
            errorName: false,
            errorFromDate: false,
            errorToDate: false,
            catalog: {},
            productsInCatalog: [],
            participantsInCatalog: [],
            productDatatableConfig: [
                { 'field' : 'id', head : this.$t("shops.id"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'active', 'type': 'check', head : this.$t("shops.active"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'name', head : this.$t("shops.name"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'type_code', head : this.$t("shops.type"), 'searchable' : false, 'sortable' : false },
            ],
            participantsDatatableConfig: [
                { 'field' : 'id', head : this.$t("shops.id"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'name', head : this.$t("shops.name"), 'searchable' : false, 'sortable' : false },
            ],
        };
    },
    methods: {
        handleSortProduct (productsSorted) {
            this.$emit("productSorting", {
                catalog: this.catalog,
                productsSorted: productsSorted
            });
        },

        handleSortParticipants (participantsSorted) {
            this.$emit("participantSorting", {
                catalog: this.catalog,
                participantsSorted: participantsSorted
            });
        },

        validateAndSubmit() {
            const payload = {...this.languages().reduce((carry, lang) => {
                carry[lang] = this.catalog.translations[lang]
                return carry
            }, {}), ...this.catalog}

            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$emit("submit", payload);
                } else {
                    this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.catalog_validation_failed"));
                    if (!this.catalog.name) this.errorName = true;
                    if (!this.catalog.fromDate) this.errorFromDate = true;
                    if (!this.catalog.toDate) this.errorToDate = true;
                }
            });
        }
    },

    created() {
        this.catalog = JSON.parse(JSON.stringify(this.value))
        if (this.catalog.products) {
            this.productsInCatalog = this.catalog.products.map(
                element => { 
                    return {...element, uuid: element.id, type_code: element.type.code}
                }
            );
        }

        if (this.catalog.participants) {
            this.participantsInCatalog = this.catalog.participants.map(
                element => { 
                    return { ...element, uuid: element.id }
                }
            );
        }

       
    }

};
</script>

<style>
</style>
